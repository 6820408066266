import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2";
import { GlobalContext } from "../context/GlobalContext";

export const SessionLogout = () => {

    let navigate = useNavigate();
    const globalContext = useContext(GlobalContext);

    // Whenever the sessionComplete globalstate will get true this component will render once
    useEffect(() => {
        Swal.fire("Your session has expired, Please login again.").then(() => {
            sessionStorage.clear();
            globalContext.setLoggedIn(false);
            navigate('/')
        })
    }, [])

    return true

}