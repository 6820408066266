import React from "react";
import { Home } from "../components/dashboard/Home";

export const HomeRoute = [
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/home",
        element: <Home />
    }
]