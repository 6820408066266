import React from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ForgotToken } from "../../services/Services";

export const ForgotPasswordToken = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setError } = useForm();

    const forgotPassword = (data) => {
        ForgotToken(data).then(res => {
            Swal.fire(res.data.message).then(() => {
                navigate("/")
            })
        }).catch(err => {
            setError('email', { type: 'custom', message: err.response.data.message });
        })
    }

    return (
        <div className="login-area">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="logo-top">
                            <img src={require("../../assets/images/logo.png")} alt="logo" />
                        </div>
                        <div className="login-content-area">
                            <h2>Forgot Password</h2>
                            <Form onSubmit={handleSubmit(forgotPassword)}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        {...register("email", { required: "Email is required" })}
                                    />
                                    {errors.email && <span className="err-msg">{errors.email.message}</span>}
                                </Form.Group>
                                <Button variant="primary" type="submit">Forgot Password</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}