import React, { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { GlobalContext } from "../../context/GlobalContext";
import OTPInput from "otp-input-react";
import { useState } from "react";
import { ValidateCode } from "../../services/Services";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const VerifyOtp = () => {

    const globalContext = useContext(GlobalContext);
    const navigate = useNavigate();
    const [OTP, setOTP] = useState("");

    function verifyCode() {
        if (OTP.length) {
            let params = {
                username: globalContext.userInfo.email,
                code: OTP
            }
            ValidateCode(params).then(res => {
                sessionStorage.setItem('user-info', JSON.stringify(res.data.data))
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                globalContext.setLoggedIn(true);
                if (res.data.data.role === "ADMIN") {
                    navigate("/home");
                } else {
                    navigate("/profile")
                }
            }).catch(err => {
                Swal.fire(err.response.data.message, '', 'error')
            })
        }
    }

    return (
        <div className="login-area">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="logo-top">
                            <img src={require("../../assets/images/logo.png")} alt="logo" />
                        </div>
                        <div className="login-content-area">
                            <h2>Enter Google Authenticated Code</h2>
                            <OTPInput
                                value={OTP}
                                onChange={setOTP}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                secure
                                className="otp-area"
                            />
                            <Button
                                variant="primary"
                                className="verify-otp-btn"
                                onClick={verifyCode}
                            >
                                Verify OTP
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
