import React from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ResetPassword } from "../../services/Services";

export const ResetNewPassword = () => {

    const navigate = useNavigate();
    const params = useParams();
    const { register, handleSubmit, formState: { errors }, setError } = useForm();

    const resetPassword = (data) => {
        let param = data
        param["token"] = params.token
        ResetPassword(param).then(res => {
            console.log(res.data);
            Swal.fire(res.data.message).then(() => {
                navigate("/")
            })
        }).catch(err => {
            setError('newPassword', { type: 'custom', message: err.response.data.message });
        })
    }

    return (
        <div className="login-area">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="logo-top">
                            <img src={require("../../assets/images/logo.png")} alt="logo" />
                        </div>
                        <div className="login-content-area">
                            <h2>Reset Password</h2>
                            <Form onSubmit={handleSubmit(resetPassword)}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter password"
                                        {...register("newPassword", { required: "Password is required" })}
                                    />
                                    {errors.newPassword && <span className="err-msg">{errors.newPassword.message}</span>}
                                </Form.Group>
                                <Button variant="primary" type="submit">Reset Password</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
