import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const BankDetailModal = ({ show, handleClose, bankDetails }) => {
  const hasBankDetails = bankDetails && typeof bankDetails === 'object' && Object.keys(bankDetails).length > 0;

  return (
    <Modal show={show} onHide={handleClose} className='bank-details-modal' centered>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title className='text-light'>Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hasBankDetails ? (
          <>
            <p className='modal-text'><strong>Account Holder Name:</strong> {bankDetails.accountHolderName ? bankDetails.accountHolderName : "N/A"}</p>
            <p className='modal-text'><strong>Account Number:</strong> {bankDetails.accountNo ? bankDetails.accountNo: "N/A"}</p>
            <p className='modal-text'><strong>Routing Number:</strong> {bankDetails.routingNumber ? bankDetails.routingNumber : "N/A"}</p>
            <p className='modal-text'><strong>City:</strong> {bankDetails.city ? bankDetails.city:"N/A"}</p>
            <p className='modal-text'><strong>State:</strong> {bankDetails.state? bankDetails.state : "N/A"}</p>
            <p className='modal-text'><strong>Line 1:</strong> {bankDetails.line1? bankDetails.line1 : "N/A"}</p>
            <p className='modal-text'><strong>Postal Code:</strong> {bankDetails.postalCode ? bankDetails.postalCode : "N/A"}</p>
            <p className='modal-text'><strong>Apartment Number:</strong> {bankDetails.apartmentNumber && bankDetails.apartmentNumber != ""? bankDetails.apartmentNumber : "N/A"}</p>
          </>
        ) : (
          <p className='modal-text'>No bank details added.</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BankDetailModal;
