import React from 'react';
import "./App.css";
import "./assets/css/style.css";
import Admin from "./common/Admin";
import { Routing } from "./routing/Routing";

function App() {
  return (
    <>
      {/* Admin is the outer layout inside this every component will get render */}
      <Admin>
        {/* Routing component where app paths are mentioned */}
        <Routing />
      </Admin>
    </>
  );
}

export default App;
