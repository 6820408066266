import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { DataPagination } from "../../common/DataPagination";
import { ShowFormatedDate } from "../../common/FormatDate";
import { ReactTooltip } from "../../common/ReactTooltip";
import { SubText } from "../../common/SubText";
import { WeiToEth } from "../../common/WeiToEth";
import {
    NftTransactionApi,
    TransactionActivity,
    TransactionsApi,
    UserTransactionsApi,
} from "../../services/Services";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_WALLET } from "../../configuration/Config";

export const CommonTransactions = (props) => {

    const navigate = useNavigate();
    let location =useLocation();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [msg, setMsg] = useState("Loading...");
    const [totalItems, setTotalItems] = useState(0);
    const [dateFilter, setDateFilter] = useState({ start: "", end: "" });

    useEffect(() => {
        setMsg("Loading...");
        // Get particular user transactions
        if (props.apiType === "userTransactions") getUserTransactions();
        // Get recent 5 transactions
        if (props.apiType === "recentTransactions") getRecentTrans();
        // Get particular nft transactions
        if (props.apiType === "nftTransactions") getNftTransaction();
    }, [props, page]);

    const getUserTransactions = () => {
        if (props.id) {
            UserTransactionsApi(props.id, page - 1)

                .then((res) => {
                    setMsg("No Data Found");
                    setData(res.data.data);
                    setTotalItems(res.data.total_records);
                })
                .catch((err) => {
                    console.log("user transactions error ", err);
                });
        } else {
            setMsg("No Data Found");
            setData([]);
            setTotalItems(0);
        }
    };

    const getRecentTrans = () => {
        TransactionsApi(0, dateFilter.start, dateFilter.end)
            .then((res) => {
                setMsg("No Data Found");
                setData(res.data.data);
                props.setCount(res.data.data.length)
            })
            .catch((err) => {
                console.log("recent transaction error ", err);
            });
    };

    const getNftTransaction = () => {
        if (props.id) {
            NftTransactionApi(props.id, page - 1)
                .then((res) => {
                    setMsg("No Data Found");
                    setData(res.data.data);
                    setTotalItems(res.data.total_records);
                })
                .catch((err) => {
                    console.log("nft transactions error ", err);
                });
        } else {
            setMsg("No Data Found");
            setData([]);
            setTotalItems(0);
        }
    };

    return (
        <div className="dashboad-table user-management-right-area mt-0">
            <Table bordered responsive>
                <thead>
                    <tr>
                        {location.pathname !=="/home" && <th>Event</th>}
                        <th>Trading Cards</th>
                        <th>Quantity</th>
                        <th>Price</th>
                       {location.pathname =="/home" && <th>Commission</th>}
                        <th>From User Name</th>
                        <th>To User Name</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length && (props.id || props.apiType === "recentTransactions") ? (
                        data.map((d, i) => (
                            
                            <tr key={i}>
                               {location.pathname !=="/home" &&
                                 <td>
                                    <img
                                        src={require("../../assets/images/minted.png")}
                                        alt="sale"
                                    />{" "}
                                    {d.log_type ? d.log_type.replace(/_/g, ' '):"ITEM BUY"}
                                </td>}
                                <td>
                                    <div>{d.nft_name ?<span role="button" onClick={() => navigate(`/tradingcards_management?page=1&search=${d.nft_name}`)}>{d.nft_name}</span> : "N/A"}</div>
                                </td>
                                <td>{d.quantity ? d.quantity : "1"}</td>
                                {location.pathname =="/home" ?
                                <td>{d.total_Price ? "$ " +Number(d.total_Price).toFixed(2) : "N/A"}</td>
                                :
                                <td>{d.price ? "$ " +Number(d.price).toFixed(2) : "N/A"}</td>
                                }
                                {location.pathname =="/home" &&
                                 <td>{d.admin_comission ? "$ " +Number(d.admin_comission).toFixed(2) : "N/A"}</td>}
                                <td>
                                        {d.sellerFirstName && d.sellerLastName ? `${d.sellerFirstName} ${d.sellerLastName}` : "N/A"}
                                </td>
                                <td>
                                   
                                    {d.userFirstName && d.userLastName ? `${d.userFirstName} ${d.userLastName}` : "N/A"}
                                </td>
                                <td>
                                    {ShowFormatedDate(d.created_at)}{" "}
                                   
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="not-data-found">
                                {msg}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {/* Pagination component */}
            {totalItems > 10 && (
                <DataPagination
                    page={page}
                    totalItems={totalItems}
                    changePage={setPage}
                />
            )}
        </div>
    );
};
