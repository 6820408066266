import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { CommonTransactions } from "../transactionManagement/CommonTransactions";

export const RecentTrans = () => {

    let navigate = useNavigate();
    const [count, setCount] = useState(0);

    return (
        <>
            <h2 className="transactions">Recent Transactions</h2>
            <div className="dashboad-table transaction-dashboard">
                <CommonTransactions apiType="recentTransactions" setCount={setCount} />
            </div>
            {count >= 5 &&
                <div className="add-btn view-btn">
                    <Button
                        type="button"
                        variant="unset"
                        onClick={() => navigate("/transaction_management?page=0")}
                    >
                        View More
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Button>
                </div>
            }
        </>
    )
}