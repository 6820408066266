import React, { useContext } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { DisableFa } from "../../services/Services";

export const DisableTwoFa = ({ setUpdatePage }) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const globalContext = useContext(GlobalContext);

    const disable = (data) => {
        DisableFa(data).then(res => {
            setUpdatePage(updatePage => updatePage + 1);
            sessionStorage.setItem('user-info', JSON.stringify(res.data.data))
            globalContext.setUserInfo(res.data.data)
        }).catch(err => {
            Swal.fire(err.response.data.message, '', 'error')
            console.log("disable fa error ", err);
        })
    }

    return (
        <div className="step-to-step">
            <h2>Disable</h2>
            <h6>2FA is enabled, Enter code to disable this</h6>
            <Form onSubmit={handleSubmit(disable)}>
                <div className="qr-code mt-4">
                    <div>
                        <Form.Label>Google authentication code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter code"
                            {...register("code", { required: "Code is required" })}
                        />
                        {errors.code && <span className="err-msg">{errors.code.message}</span>}
                    </div>
                </div>
                <Button
                    type="submit"
                    className="next-bt"
                >
                    Disable
                </Button>
            </Form>
        </div>
    )
}