import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { UpdateAdmin } from "../../services/Services";

export const UpdateProfile = ({ show, setShow }) => {
  const globalContext = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [img, setImg] = useState({ file: "", src: "" });

  useEffect(() => {
    setValue("firstname", globalContext.userInfo.firstname);
    setValue("lastname", globalContext.userInfo.lastname);
  }, [show]);

  const updateAdmin = (data) => {
    const formData = new FormData();
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    if (img.file) formData.append("profile", img.file);
    globalContext.setLoader(true);
    UpdateAdmin(formData)
      .then((res) => {
        sessionStorage.setItem("user-info", JSON.stringify(res.data.data));
        globalContext.setUserInfo(res.data.data);
        globalContext.setLoader(false);
        Swal.fire("Profile updated successfully").then(() => setShow(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          globalContext.setSessionComplete(true);
        }
        globalContext.setLoader(false);
        Swal.fire(err.response.data.message, "", "error");
      });
  };

  return (
    <Modal
      className="edit-profile-modal-area"
      show={show}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="profile-main-image">
            <img
              alt="user"
              src={img.src ? img.src : globalContext.userInfo.profile}
            />
            <Form.Control
              type="file"
              accept="image/*"
              onChange={(e) =>
                setImg({
                  file: e.target.files[0],
                  src: URL.createObjectURL(e.target.files[0]),
                })
              }
            />
          </div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              {...register("firstname", { required: "First name is required", pattern: { value: /^\S*$/, message: "Spaces are not allowed" } })}
            />
            {errors.firstname && (
              <span className="err-msg">{errors.firstname.message}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              {...register("lastname", { required: "Last name is required", pattern: { value: /^\S*$/, message: "Spaces are not allowed" } })}
            />
            {errors.lastname && (
              <span className="err-msg">{errors.lastname.message}</span>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit(updateAdmin)}>
          Update Profile
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
