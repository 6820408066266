import React, { useContext } from "react"
import { Form } from "react-bootstrap";
import { GlobalContext } from "../context/GlobalContext";
import { SelectParticularData, UnSelectParticularData } from "./CsvData";

export const SelectParticularItem = ({ item }) => {

    const globalContext = useContext(GlobalContext);

    const selectOption = (val, item) => {
        // Select/Unselect particular data
        globalContext.setSelectedData(SelectParticularData(val, globalContext.selectedData, item))
        // Unselected data
        globalContext.setUnSelectedData(UnSelectParticularData(val, globalContext.unSelectedData, item))
        if (!val) {
            // on unselect, false the selectAll state
            globalContext.setSelectAllUsers(false)
            globalContext.setSelectAllTrans(false)
            globalContext.setSelectAllNft(false)
        }
    }

    return (
        <div className="user-management-check">
            <Form.Check aria-label="option 1" checked={globalContext.selectedData.some((s) => s === item)} onChange={(e) => selectOption(e.target.checked, item)} />
        </div>
    )
}