import React from "react";
import { useState } from "react";
import { GlobalContext } from "./GlobalContext";

export const GlobalState = (props) => {

    // loader is used to manage states before data load
    const [loader, setLoader] = useState(false);
    // loggedIn state to check that user is logged in or not
    const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem('user-info') && JSON.parse(sessionStorage.getItem('user-info')).token ? true : false);
    // userInfo is used to save the user data
    const [userInfo, setUserInfo] = useState(sessionStorage.getItem('user-info') ? JSON.parse(sessionStorage.getItem('user-info')) : {});
    // sessionComplete is used to manage the session logout popup
    const [sessionComplete, setSessionComplete] = useState(false);
    // used for export csv functionality
    const [selectedData, setSelectedData] = useState([]);
    // used for export csv without functionality
    const [unSelectedData, setUnSelectedData] = useState([]);
    // select all different buttons because state takes time to get set
    const [selectAllUsers, setSelectAllUsers] = useState(false);
    const [selectAllTrans, setSelectAllTrans] = useState(false);
    const [selectAllNft, setSelectAllNft] = useState(false);

    const value = {
        loader, setLoader,
        loggedIn, setLoggedIn,
        userInfo, setUserInfo,
        sessionComplete, setSessionComplete,
        selectedData, setSelectedData,
        unSelectedData, setUnSelectedData,
        selectAllUsers, setSelectAllUsers,
        selectAllTrans, setSelectAllTrans,
        selectAllNft, setSelectAllNft,
    }

    return (
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
    )

}