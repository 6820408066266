import React, { useContext, useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { GetProfile } from "../services/Services";

const Header = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const globalContext = useContext(GlobalContext);

  // Handled the logout functionality
  function signOut() {
    sessionStorage.clear();
    globalContext.setLoggedIn(false);
    navigate("/");
  }

  // This useeffect is for settings selected data and select all to initial state
  useEffect(() => {
    globalContext.setSelectAllUsers(false);
    globalContext.setSelectAllTrans(false);
    globalContext.setSelectAllNft(false);
    globalContext.setSelectedData([]);
    globalContext.setUnSelectedData([]);
    getUserProfile()
  }, [location.pathname]);

  const getUserProfile = () => {
    GetProfile().then(res => {
      sessionStorage.setItem('user-info', JSON.stringify(res.data.data))
      globalContext.setUserInfo(res.data.data);
    }).catch(err => {
      console.log("get user profile detail error ", err);
    })
  }

  return (
    <>
      <div className="admin-header">
        <Container fluid>
          <div className="header-right">
            {/* <div className="wallet-btn">
              <Button type="button" variant="unset">
                Connect Wallet
              </Button>
            </div> */}
            <div className="logout-btn">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img alt="" src={globalContext.userInfo.profile} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("/fa_setup")}>
                    <div className="header-dropdown-top">
                      <div>
                        <i className="fa fa-user" aria-hidden="true"></i>
                        2FA
                      </div>

                      <div className="user-toggle">
                        <label className="switch">
                          <input type="checkbox" checked={globalContext.userInfo.user_2fa_status === "ENABLED" ? true : false} disabled />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/profile")}>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={signOut}>
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Header;
