import React, { useContext, useEffect, useState } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { DataPagination } from "../../common/DataPagination";
import { ImageShimmer } from "../../common/ImageShimmer";
import { SearchBar } from "../../common/SearchBar";
import { GlobalContext } from "../../context/GlobalContext";
import { DeleteSubAdmin, SubAdminListingApi } from "../../services/Services";
import { AdminSettings } from "./AdminSettings";
import { CreateAdmin } from "./CreateAdmin";
import { SystemLogs } from "./SystemLogs";

export const SubAdmin = () => {

  const [query] = useSearchParams();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [detailUser, setDetailUser] = useState({});
  const [msg, setMsg] = useState("Loading...")
  // this is used to re-render the page when any other related component changes
  const [updatePage, setUpdatePage] = useState(0);

  useEffect(() => {
    setMsg("Loading...");
    let currentPage = query.get("page");
    setPage(parseInt(currentPage));
    getSubAdmin(parseInt(currentPage) - 1);
  }, [query, search, updatePage]);

  const getSubAdmin = (currentPage) => {
    SubAdminListingApi(currentPage, search).then(res => {
      setMsg("No Data Found");
      setData(res.data.data);
      setDetailUser(res.data.data[0]);
      setTotalItems(res.data.total_records)
    }).catch(err => {
      if (err.response.status === 401) {
        globalContext.setSessionComplete(true);
      }
      console.log("sub admin error ", err);
    })
  }

  const deleteSubAdmin = (user) => {
    Swal.fire({
      title: `Delete Sub-Admin`,
      text: "Are you sure, you want to delete sub-admin?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteSubAdmin(user).then(res => {
          setUpdatePage(updatePage => updatePage + 1);
          Swal.fire("Sub-admin deleted successfully")
        }).catch(err => {
          if (err.response.status === 401) {
            globalContext.setSessionComplete(true)
          }
          Swal.fire(err.response.data.message, '', 'error')
        })
      }
    });
  }

  const changePage = (newPage) => {
    navigate({ search: `?page=${newPage}` });
  };

  const searchChange = (e) => {
    navigate({ search: `?page=${1}` });
    setSearch(e.target.value.trimStart())
  };

  return (
    <>
      <Container fluid>
        <div className="subadmin-area">
          <Row>
            <Col md={3}>
              <div className="sub-admin-content-area mb-4">
                <h5>Search Users</h5>
                {/* search bar component */}
                <div className="user-searchbar subadmin-search-area">
                  <SearchBar search={search} searchChange={searchChange} />
                </div>
              </div>
              {/* Create sub-admin component */}
              <CreateAdmin setUpdatePage={setUpdatePage} />
              {/* Edit sub-admin and handle permissions */}
              <AdminSettings {...detailUser} setUpdatePage={setUpdatePage} />
            </Col>
            <Col md={4}>
              <div className="dashboad-table user-management user-content-area">
                <div className="user-list-admin">
                  <h5>User List</h5>
                </div>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length ? (
                      data.map((d, i) => (
                        <tr
                          key={i}
                          className={`list-data ${detailUser === d ? "active" : ""}`}
                          onClick={() => setDetailUser(d)}
                        >
                          <td>{d.username}</td>
                          <td>{d.email}</td>
                          <td className="nft-pitcures">
                            {/* Shimmer component for effect till image doesn't get loaded */}
                            <ImageShimmer source={d.profile} width={35} height={35} />
                          </td>
                          <td>
                            <i onClick={() => deleteSubAdmin(d.id)} role="button" className="fa fa-trash" aria-hidden="true"></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="not-data-found">
                          {msg}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* Pagination component */}
                {totalItems > 10 &&
                  <DataPagination page={page} totalItems={totalItems} changePage={changePage} />
                }
              </div>
            </Col>
            <Col md={5}>
              {/* Admin System logs component */}
              <SystemLogs {...detailUser} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
