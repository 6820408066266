import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import Footer from "./Footer";
import Header from "./Header";
import { Loader } from "./Loader";
import { SessionLogout } from "./SessionLogout";
import Sidebar from "./Sidebar.js";

const Admin = (props) => {
  const globalContext = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Loader component for loading effect */}
      {globalContext.loader && <Loader />}
      {/* SessionLogout component for 401(Unauthorized users) */}
      {globalContext.sessionComplete && <SessionLogout />}
      <div className={`mian_content ${isOpen === false ? "active" : ""}`}>
        {globalContext.loggedIn && (
          <>
            {/* App header */}
            <Header />
            {/* App sidebar */}
            <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
          </>
        )}
        {/* Render the routing components here */}
        <div
          className={
            globalContext.loggedIn ? "content" : "content content-home"
          }
        >
          <div className={!globalContext.loggedIn ? "" : "custom_height"}>
            {props.children}
          </div>
        </div>
        {/* App footer */}
        {globalContext.loggedIn && <Footer />}
      </div>
    </>
  );
};
export default Admin;
