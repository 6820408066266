import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { LoginApi } from "../../services/Services";

export const Login = () => {

  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const { register, handleSubmit, formState: { errors }, setError } = useForm();

  const onSubmitLogin = (data) => {
    globalContext.setLoader(true)
    LoginApi(data).then(res => {
      sessionStorage.setItem('user-info', JSON.stringify(res.data.data));
      globalContext.setUserInfo(res.data.data);
      globalContext.setSessionComplete(false);
      globalContext.setLoader(false)
      if (res.data.data.token) {
        globalContext.setLoggedIn(true);
        if (res.data.data.role === "ADMIN") {
          navigate("/home");
        } else {
          navigate("/profile")
        }
      } else {
        navigate("/verify_otp")
      }
    }).catch(err => {
      globalContext.setLoader(false)
      setError('password', { type: 'custom', message: err.response.data.message });
    })
  }

  return (
    <div className="login-area">
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="logo-top">
              <img src={require("../../assets/images/logo.png")} alt="logo" />
            </div>
            <div className="login-content-area">
              <h2>Login</h2>
              <Form onSubmit={handleSubmit(onSubmitLogin)}>
                <Form.Group className="mb-3" >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    {...register("username", { required: "Email is required" })}
                  />
                  {errors.username && <span className="err-msg">{errors.username.message}</span>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    {...register("password", { required: "Password is required" })}
                  />
                  {errors.password && <span className="err-msg">{errors.password.message}</span>}
                </Form.Group>
                {/* <Link to="/forgot_password" className="forgetpassword">Forgot Password?</Link> */}
                <Button variant="primary" type="submit">Login</Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
