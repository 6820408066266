import React from "react";

export const PageNotFound = () => {
  return (
    <div className="not-found">
      <div>
        <img src={require("../assets/images/no-data.png")} alt="not-found" />
        <h2>404 | Page Not Found</h2>
      </div>
    </div>
  );
};
