import React from "react";
import { ForgotPasswordToken } from "../components/forgotPassword/ForgotPasswordToken";
import { ResetNewPassword } from "../components/forgotPassword/ResetNewPassword";
import { Login } from "../components/login/Login";
import { VerifyOtp } from "../components/login/VerifyOtp";

export const OtherRoutes = [
    {
        path: "*",
        element: <Login />
    },
    {
        path: "/verify_otp",
        element: <VerifyOtp />
    },
    {
        path: "/forgot_password",
        element: <ForgotPasswordToken />
    },
    {
        path: "/reset_password/:token",
        element: <ResetNewPassword />
    }
]