// Select/Unselect all data
export const SelectCompleteData = (val, data) => {
    if (val) {
        return data.map(d => d.id)
    } else {
        return []
    }
}

// Select/Unselect particular data
export const SelectParticularData = (val, data, item) => {
    let temp = [...data]
    if (val) {
        // select particular data
        temp.push(item)
    } else {
        // unselect item
        temp.splice(temp.indexOf(item), 1)
    }
    return temp
}

export const UnSelectParticularData = (val, data, item) => {
    let temp = [...data]
    if (!val) {
        temp.push(item)
    } else {
        if (temp.some((s) => s === item)) temp.splice(temp.indexOf(item), 1)
    }
    return temp
}
