import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { UpdateUserStatus, UserListApi } from "../../services/Services";
import { UserData } from "./userData";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { DataPagination } from "../../common/DataPagination";
import { SubText } from "../../common/SubText";
import { CommonTransactions } from "../transactionManagement/CommonTransactions";
import { ReactTooltip } from "../../common/ReactTooltip";
import { SelectAllDataCsv } from "../../common/SelectAllDataCsv";
import { SelectParticularItem } from "../../common/SelectParticularItem";
import { SearchBar } from "../../common/SearchBar";
import { ExportCsvData } from "../../common/ExportCsvData";

export const UserManagement = () => {
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "All",
  });
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [detailUser, setDetailUser] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [msg, setMsg] = useState("Loading...");

  const options = [
    { value: "", label: "All" },
    { value: "latest", label: "New Users" },
  ];

  useEffect(() => {
    setMsg("Loading...");
    let currentPage = query.get("page");
    setPage(parseInt(currentPage));
    getUsers(parseInt(currentPage) - 1);
  }, [query, search, selectedOption]);

  const getUsers = (currentPage) => {
    globalContext.setLoader(true);
    UserListApi(
      query.get("search") ? query.get("search") : search,
      currentPage,
      selectedOption.value
    )
      .then((res) => {
        globalContext.setLoader(false);
        setMsg("No Data Found");
        setData(res.data.data);
        setDetailUser(res.data.data[0]);
        setTotalItems(res.data.total_records);
        // when selected all, change check box data
        if (globalContext.selectAllUsers || selectAll) {
          let temp = [...globalContext.selectedData];
          // only push id when not already in the array
          res.data.data.forEach((d) => {
            if (
              !globalContext.selectedData.some((s) => s === d.id) &&
              !globalContext.unSelectedData.some((s) => s === d.id)
            )
              temp.push(d.id);
          });
          globalContext.setSelectedData(temp);
        }
      })
      .catch((err) => {
        globalContext.setLoader(false);
        if (err.response.status === 401) {
          globalContext.setSessionComplete(true);
        }
        console.log("user management error ", err);
      });
  };

  const changeUserStatus = (user, index) => {
    Swal.fire({
      title: `${user.user_status === "1" ? "Deactivate" : "Activate"}`,
      text: `Are you sure, you want to ${
        user.user_status === "1" ? "deactivate" : "activate"
      } user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          status: user.user_status === "0" ? 1 : 0,
        };
        UpdateUserStatus(params, user.id)
          .then((res) => {
            let temp = [...data];
            temp[index].user_status = user.user_status === "0" ? "1" : "0";
            setData(temp);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              globalContext.setSessionComplete(true);
            }
            console.log("user update status error ", err);
          });
      }
    });
  };

  const changePage = (newPage) => {
    navigate({ search: `?page=${newPage}` });
  };

  const searchChange = (e) => {
    navigate({ search: `?page=${1}` });
    setSearch(e.target.value.trimStart());
  };

  return (
    <>
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <h2>User Management</h2>
        </div>

        <Row className="pb-4">
          <Col xxl={5} md={5}>
            <div className="dashboad-table user-management user-content-area">
              <div className="management-area-top">
                <div className="export-btn">
                  {/* Export csv component */}
                  <ExportCsvData data="users" selectAll={selectAll} />
                </div>
                <div className="user-management filter-data filter-data-management">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={options}
                      placeholder="All/New user"
                    />
                  </Form.Group>
                </div>
                {/* search bar component */}
                <div className="user-searchbar search-data">
                  <SearchBar
                    search={query.get("search") ? query.get("search") : search}
                    searchChange={searchChange}
                  />
                </div>
              </div>

              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>
                      <SelectAllDataCsv
                        data={data}
                        type="user"
                        setSelectAll={setSelectAll}
                      />
                    </th>
                    <th>Name</th>
                    {/* <th>Wallet Address</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length ? (
                    data.map((d, i) => (
                      <tr
                        key={i}
                        className={`list-data ${
                          detailUser === d ? "active" : ""
                        }`}
                        onClick={() => setDetailUser(d)}
                      >
                        <td>
                          <SelectParticularItem item={d.id} />
                        </td>
                        <td>{d.firstname + " " + d.lastname}</td>
                        {/* <td>
                          {d.ethAddress ? (
                            <ReactTooltip text={d.ethAddress}>
                              <div className="">{SubText(d.ethAddress)}</div>
                            </ReactTooltip>
                          ) : (
                            "N/A"
                          )}
                        </td> */}
                        {globalContext.userInfo.role === "ADMIN" ||
                        globalContext.userInfo.user_mgt_write === 1 ? (
                          <td>
                            <ReactTooltip
                              text={
                                d.user_status === "1" ? "Disable" : "Enable"
                              }
                            >
                              <div className="user-toggle" id="my-element">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => changeUserStatus(d, i)}
                                    checked={
                                      d.user_status === "1" ? true : false
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </ReactTooltip>
                          </td>
                        ) : (
                          <td>
                            {d.user_status === "1"
                              ? "Activated"
                              : "Deactivated"}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="not-data-found">
                        {msg}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* Pagination component */}
              {totalItems > 10 && (
                <DataPagination
                  page={page}
                  totalItems={totalItems}
                  changePage={changePage}
                />
              )}
            </div>
          </Col>
          <Col xxl={7} md={7}>
            {/* User detail page component */}
            <UserData {...detailUser} />
            {/* Particular common transactions component */}
            <CommonTransactions {...detailUser} apiType="userTransactions" />
          </Col>
        </Row>
      </Container>
    </>
  );
};
