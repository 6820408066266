import React from "react";
import { Home } from "../components/dashboard/Home";
import { NftManagement } from "../components/nftManagement/NftManagement";
import { SubAdmin } from "../components/subAdmin/SubAdmin";
import { UserManagement } from "../components/userManagement/UserManagement";
import { TransactionManagement } from "../components/transactionManagement/TransactionManagement"
import { PayoutManagement } from "../components/payoutManagement/PayoutManagement";
import { PayoutDetail } from "../components/payoutManagement/PayOutDetail";

export const AdminRoutes = [
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/home",
        element: <Home />
    },
    {
        path: "/user_management",
        element: <UserManagement />
    },
    {
        path: "/tradingcards_management",
        element: <NftManagement />
    },
    {
        path: "/sub_admin",
        element: <SubAdmin />
    },
    {
        path: "/transaction_management",
        element: <TransactionManagement />
    },
    {
        path: "/payout_management",
        element: <PayoutManagement />
    },
    {
        path: "/payout_details",
        element: <PayoutDetail />
    }
]