import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { DataPagination } from "../../common/DataPagination"
import { FormatDate, ShowFormatedDate } from "../../common/FormatDate"
import { SearchBar } from "../../common/SearchBar"
import { SubAdminLogs } from "../../services/Services"

export const SystemLogs = (props) => {

    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [msg, setMsg] = useState("Loading...");
    const [dateFilter, setDateFilter] = useState({ start: "", end: "" });
    const todayDate = FormatDate(new Date());

    useEffect(() => {
        setMsg("Loading...");
        getLogs();
    }, [props, search, page, dateFilter])

    const getLogs = () => {
        if (props.id) {
            SubAdminLogs(props.id, page - 1, dateFilter.start, dateFilter.end, search).then(res => {
                setData(res.data.data)
                setMsg("No Data Found");
                setTotalItems(res.data.total_records)
            }).catch(err => {
                console.log("sub admin log error ", err);
            })
        } else {
            setMsg("No Data Found");
        }
    }

    const changeStartDate = (date) => {
        if (dateFilter.end && date <= dateFilter.end && date <= todayDate) {
            setDateFilter({ ...dateFilter, start: date })
        } else if (!dateFilter.end && date <= todayDate) {
            setDateFilter({ ...dateFilter, start: date })
        }
    }

    const changeEndDate = (date) => {
        if (date <= todayDate && date >= dateFilter.start) setDateFilter({ ...dateFilter, end: date })
    }

    const searchChange = (e) => {
        setPage(1);
        setSearch(e.target.value.trimStart());
    };

    return (
        <div className="sub-admin-content-area mb-4   h-100">
            <h5>System Logs</h5>
            <div className="d-flex p-3 system-log-flex">
                <div className="system-log-area-date-picker">
                    <input type="date" max={dateFilter.end ? dateFilter.end : todayDate} value={dateFilter.start} onChange={(e) => changeStartDate(e.target.value)} />
                </div>
                <div className="system-log-area-date-picker">
                    <input type="date" min={dateFilter.start} max={todayDate} disabled={dateFilter.start ? false : true} value={dateFilter.end} onChange={(e) => changeEndDate(e.target.value)} />
                </div>
                {/* search bar component */}
                <div className="user-searchbar subadmin-search-area p-0">
                    <SearchBar search={search} searchChange={searchChange} />
                </div>
            </div>

            <Table bordered responsive>
                <thead>
                    <tr className="user-setting-area">
                        <th>Event</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data.length ?
                        data.map((d, i) =>
                            <tr key={i}>
                                <td>{d.log}</td>
                                <td>{ShowFormatedDate(d.created_at)}</td>
                            </tr>) : <tr>
                            <td colSpan="5" className="not-data-found">
                                {msg}
                            </td>
                        </tr>}
                </tbody>
            </Table>
            {/* Pagination Component */}
            {totalItems > 10 &&
                <DataPagination page={page} totalItems={totalItems} changePage={setPage} />
            }
        </div>
    )
}