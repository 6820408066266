import React, { useContext } from "react"
import { Form } from "react-bootstrap";
import { GlobalContext } from "../context/GlobalContext";
import { SelectCompleteData } from "./CsvData";

export const SelectAllDataCsv = ({ data, type, setSelectAll }) => {

    const globalContext = useContext(GlobalContext);

    const selectAllData = (val) => {
        if (type === "user") globalContext.setSelectAllUsers(val)
        if (type === "trans") globalContext.setSelectAllTrans(val)
        if (type === "nft") globalContext.setSelectAllNft(val)
        // Select/Unselect all data
        globalContext.setSelectedData(SelectCompleteData(val, data))
        globalContext.setUnSelectedData([])
        setSelectAll(val)
    }

    return (
        data.length ? <div className="user-management-check">
            <Form.Check aria-label="option 1" checked={type === "user" ? globalContext.selectAllUsers : type === "trans" ? globalContext.selectAllTrans : globalContext.selectAllNft} onChange={(e) => selectAllData(e.target.checked)} />
        </div> : ""
    )

}