import React from "react";
import { Button } from "react-bootstrap";

export const SecondStep = ({ steps, setSteps, securityKey }) => {
    return (
        <div className="step-to-step">
            <h2>Step 2</h2>
            <h6>
                please save this key on paper. this key will allow you to
                recover your google authentication in case of phone loss.
            </h6>
            <div className="qr-code">
                <div>
                    <p>
                        resetting your google authentication requires opening
                        a support ticket and takes at least 7 days to process.
                        enter code manually into the app
                    </p>
                    <h3>{securityKey}</h3>
                </div>
            </div>
            <Button
                type="button"
                className="next-bt"
                onClick={() => setSteps({ ...steps, first: true, second: false })}
            >
                Back
            </Button> {" "}
            <Button
                type="button"
                className="next-bt"
                onClick={() => setSteps({ ...steps, second: false, third: true })}
            >
                Next
            </Button>
        </div>
    )
}