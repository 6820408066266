import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

export const ReactTooltip = (props) => {
    // React Bootstrap tooltip component
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    {props.text}
                </Tooltip>
            }
        >{props.children}</OverlayTrigger>
    )
}