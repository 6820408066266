import React from "react"
import Pagination from "react-js-pagination"

export const DataPagination = ({ page, changePage, totalItems }) => {

    return (
        <div className="pagination-user-management">
            <Pagination
                activePage={page}
                itemsCountPerPage={10}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={(event) => changePage(event)}
            />
        </div>
    )
}