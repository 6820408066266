import React, { useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { CreateSubAdmin } from "../../services/Services";

export const CreateAdmin = ({ setUpdatePage }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const globalContext = useContext(GlobalContext);

  const createSubAdmin = (data) => {
    globalContext.setLoader(true)
    const formData = new FormData()
    formData.append("username", data.username)
    formData.append("email", data.email)
    formData.append("password", data.password)
    formData.append("profile", data.profile[0])
    CreateSubAdmin(formData).then(res => {
        globalContext.setLoader(false)
        Swal.fire("Sub-admin created successfully").then(() => {
            reset({
                email: "",
                password: "",
                username: "",
                profile: ""
            })
            setUpdatePage(updatePage => updatePage + 1)
        })
    }).catch(err => {
        globalContext.setLoader(false)

        if (err.response.status === 401) {
            globalContext.setSessionComplete(true)
        }
        Swal.fire(err.response.data.message, '', 'error')
    })
  };

  return (
    <div className="sub-admin-content-area mb-4">
      <Form onSubmit={handleSubmit(createSubAdmin)}>
        <div className="user-update-box">
          <h5>Create New Users</h5>
          <Button type="submit" className="update-btn-box" variant="unset">
            <i className="fa fa-floppy-o" aria-hidden="true"></i>
          </Button>
        </div>
        <Row className="p-3">
          <Col md={6}>
            <div className="create-users-form-area">
              <Form.Group className="mb-2" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  {...register("username", { required: "Name is required" })}
                />
                {errors.username && (
                  <span className="err-msg">{errors.username.message}</span>
                )}
              </Form.Group>
            </div>
          </Col>
          <Col md={6}>
            <div className="create-users-form-area">
              <Form.Group className="mb-2">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <span className="err-msg">{errors.email.message}</span>
                )}
              </Form.Group>
            </div>
          </Col>
          <Col md={6}>
            <div className="create-users-form-area">
              <Form.Group className="mb-2">
                <Form.Label>User Image</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    {...register("profile", {
                      validate: (values, formValues) => values[0].size < 4000000 || "Max image size is 4MB",
                      required: "Image is required",
                    })}
                    type="file"
                    placeholder="Choose Image"
                    className="file-input"
                    accept="image/*"
                  />
                  <Form.Control
                    type="text"
                    placeholder="Choose Image"
                    className="Choose Image"
                    value={
                      watch("profile") !== undefined
                        ? watch("profile").length > 0
                          ? watch("profile")[0].name
                          : ""
                        : ""
                    }
                    disabled={true}
                  />
                  <i
                    className="fa fa-plus add-image-icon"
                    aria-hidden="true"
                  ></i>
                </div>
                {errors.profile && (
                  <span className="err-msg">{errors.profile.message}</span>
                )}
              </Form.Group>
            </div>
          </Col>
          <Col md={6}>
            <div className="create-users-form-area">
              <Form.Group className="mb-2">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password should have atleast 8 characters",
                    },
                  })}
                />
                {errors.password && (
                  <span className="err-msg">{errors.password.message}</span>
                )}
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
