import React, { useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { EnableFa } from "../../services/Services";

export const ThirdStep = ({ steps, setSteps, setUpdatePage }) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const globalContext = useContext(GlobalContext);

    function enableTwoFa(data) {
        EnableFa(data).then(res => {
            setUpdatePage(updatePage => updatePage + 1);
            sessionStorage.setItem('user-info', JSON.stringify(res.data.data))
            globalContext.setUserInfo(res.data.data)
        }).catch(err => {
            Swal.fire(err.response.data.message, '', 'error')
            console.log("enable fa error ", err);
        })
    }

    return (
        <div className="step-to-step">
            <h2>Step 3</h2>
            <h6>Enable your google authentication</h6>

            <Form onSubmit={handleSubmit(enableTwoFa)}>
                <div className="qr-code mt-4">
                    <div>
                        <Form.Label>Google authentication code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter code"
                            {...register("code", { required: "Code is required" })}
                        />
                        {errors.code && <span className="err-msg">{errors.code.message}</span>}
                    </div>
                </div>
                <Button
                    type="button"
                    className="next-bt"
                    onClick={() => setSteps({ ...steps, second: true, third: false })}
                >
                    Back
                </Button> {" "}
                <Button
                    type="submit"
                    className="next-bt"
                >
                    Submit
                </Button>
            </Form>

        </div>
    )
}