import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { PayOutDetailApi } from "../../services/Services";
import { ShowFormatedDate } from "../../common/FormatDate";
import BankDetailModal from "./BankDetailModal";

export const PayoutDetail = () => {
    const [searchParams] = useSearchParams();
    const payoutId = searchParams.get("payoutId");
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);
    const [payoutDetails, setPayoutDetails] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedBankDetails, setSelectedBankDetails] = useState({});

    useEffect(() => {
        if (payoutId) {
            fetchPayoutDetails();
        }
    }, [payoutId]);

    /* get payout details */

    const fetchPayoutDetails = () => {
        globalContext.setLoader(true);
        PayOutDetailApi(payoutId)
            .then((res) => {
                globalContext.setLoader(false);
                console.log("payout details", res.data.data);
                setPayoutDetails(res.data.data);
            })
            .catch((err) => {
                globalContext.setLoader(false);
                console.error("Error fetching payout details:", err);
            });
    };

    console.log("payout details", payoutDetails);

    const handleShow = (bankDetails) => {
        setSelectedBankDetails(bankDetails);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    if (!payoutDetails) {
        return <div>Loading...</div>;
    }

    const handleNameClick = () => {
        // if (payoutDetails.nft_type === "SINGLE") {
        //   window.open(`https://staging.sportsfinda.com/nftDetail/${payoutDetails.nft_slug}`, '_blank');
        // } else if (payoutDetails.nft_type === "MULTIPLE") {
        //   window.open(`https://staging.sportsfinda.com/collection_details/${payoutDetails.nft_slug}`, '_blank');
        // }

    };


    return (
        <Container fluid>
            <div className="dashboard-area-heading management-heading">
                <h2> <Button variant="" className="back-btn" onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Button> Payout Detail</h2>
            </div>

            <Row className="mb-4">
                <Col md={4}>
                    <Card className="project-summary user-detail">
                        <Card.Body>
                            <Card.Title>Trading Cards Details</Card.Title>
                            <Card.Text>
                                <strong>Creator:</strong> {payoutDetails.creatorFirstName + " " + payoutDetails.creatorLastName || "N/A"}
                            </Card.Text>
                            <Card.Text style={{ cursor: 'pointer', color: 'blue' }}
                                onClick={() => navigate(`/tradingcards_management?page=1&search=${payoutDetails.nft_name}`)}
                            >
                                <strong>Name:</strong> {payoutDetails.nft_name || "N/A"}
                            </Card.Text>

                            <Card.Text>
                                <strong>Description:</strong> {payoutDetails.nft_description || "N/A"}
                            </Card.Text>
                            <Card.Text>
                                <strong>Type:</strong> {payoutDetails.nft_type || "N/A"}
                            </Card.Text>

                            {payoutDetails.nft_image && (
                                <img src={payoutDetails.nft_image} alt={payoutDetails.nft_name} className="nft-images" />
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                {/* <Col md={4}>
          <Card className="project-summary user-detail">
            <Card.Body>
              <Card.Title>Trading Cards Details</Card.Title>
              <Card.Text>
                <strong>Total Amount:</strong> {payoutDetails.payable_amount ? `$ ${payoutDetails.payable_amount}` : "N/A"}
              </Card.Text>
              <Card.Text>
                <strong>Status:</strong> {payoutDetails.status}
              </Card.Text>
              <Card.Text>
                <strong>Date:</strong> {ShowFormatedDate(payoutDetails.created_at)}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

                <Col md={4}>
                    <Card className="project-summary user-detail">
                        <Card.Body>
                            <Card.Title>Buyer Details</Card.Title>
                            <Card.Text>
                                <strong>Name:</strong> {`${payoutDetails.sellerFirstName || "N/A"} ${payoutDetails.sellerLastName || "N/A"}`}
                            </Card.Text>
                            <Card.Text>
                                <strong>Actual Amount:</strong> {payoutDetails.atual_amount ? `$ ${payoutDetails.atual_amount.toFixed(2)}` : "N/A"}
                            </Card.Text>
                            <Card.Text>
                                <strong>Status:</strong>  {payoutDetails.status ? payoutDetails.status == "PENDING" ? "Pending" : "Completed" : "N/A"}
                            </Card.Text>
                            <Card.Text>
                                <strong>Total Payable Amount:</strong> {payoutDetails.payable_amount ? `$ ${payoutDetails.payable_amount.toFixed(2)}` : "N/A"}
                            </Card.Text>
                            <Card.Text>
                                <strong>Commission:</strong> {payoutDetails.admin_comission ? `$ ${payoutDetails.admin_comission.toFixed(2)}` : "N/A"}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>




        </Container>
    );
};
