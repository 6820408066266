import React, { useContext, useEffect, useState } from "react";
import { Container, Table, Dropdown } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataPagination } from "../../common/DataPagination";
import { DateRangeFilter } from "../../common/DateRangeFilter";
import { ExportCsvData } from "../../common/ExportCsvData";
import { ShowFormatedDate } from "../../common/FormatDate";
import { ReactTooltip } from "../../common/ReactTooltip";
import { SelectAllDataCsv } from "../../common/SelectAllDataCsv";
import { SelectParticularItem } from "../../common/SelectParticularItem";
import { SubText } from "../../common/SubText";
import { WeiToEth } from "../../common/WeiToEth";
import { GlobalContext } from "../../context/GlobalContext";
import { GetBankInfoApi, PayOutListApi, TransactionsApi, UpdatePayoutStatusApi } from "../../services/Services";
import { ADMIN_WALLET } from "../../configuration/Config";
import { SearchBar } from "../../common/SearchBar";
import BankDetailModal from "./BankDetailModal";

export const PayoutManagement = () => {
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [dateFilter, setDateFilter] = useState({ start: "", end: "" });
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");
  const [msg, setMsg] = useState("Loading...")
  const [totalearning, setTotalEarning] = useState()
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    setMsg("Loading...");
    let currentPage = query.get("page");
    setPage(parseInt(currentPage));
    getTransactions(selectedStatus, search, parseInt(currentPage) - 1);
  }, [selectedStatus, search, page, query]);


  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };
  /* get Payout list */
  const getTransactions = (currentPage) => {
    globalContext.setLoader(true)
    PayOutListApi(selectedStatus, search, page - 1)
      .then((res) => {
        globalContext.setLoader(false)
        setMsg("No Data Found");
        setData(res.data.data);
        setTotalEarning(res.data.totalAdminComission)
        setTotalItems(res.data.total_records);
        // when selected all, change check box data
        if (globalContext.selectAllTrans || selectAll) {
          let temp = [...globalContext.selectedData]
          // only push id when not already in the array
          res.data.data.forEach(d => {
            if (!globalContext.selectedData.some((s) => s === d.id) && !globalContext.unSelectedData.some((s) => s === d.id)) temp.push(d.id)
          })
          globalContext.setSelectedData(temp)
        }
      })
      .catch((err) => {
        globalContext.setLoader(false)
        if (err.response.status === 401) {
          globalContext.setSessionComplete(true);
        }
        console.log("transaction management error ", err);
      });
  };

  const changePage = (newPage) => {
    navigate({ search: `?page=${newPage}` });
  };

  const searchChange = (e) => {
    navigate({ search: `?page=${1}` });
    setSearch(e.target.value.trimStart())
  };

  /* show and hide bank modal */
  const [showModal, setShowModal] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState({});

  const handleShow = (bankDetails) => {
    getBankDetails(bankDetails);
  };

  console.log("selected bank details", selectedBankDetails);

  const handleClose = () => {
    setShowModal(false);
  };
  // Filtered data based on selected status
  // const filteredData = data.filter(d => d.status === selectedStatus);

  /* get Bank details of payout list/user */

  function getBankDetails(id) {
    // globalContext.setLoader(true);
    GetBankInfoApi(id)
      .then((res) => {
        globalContext.setLoader(false);
        console.log("bank details", res.data.data);
        setSelectedBankDetails(res.data.data);
        setShowModal(true);
        // handleShow(res.data.data);

      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.error("Error fetching bank details:", err);
      });
  }

  const handleStatusChangeList = (payoutId, newStatus) => {
    // console.log("payoutId, newStatus",payoutId, newStatus);
    const params = {
      payout_id: payoutId.id,
      status: newStatus
    }
    console.log("params", params);
    globalContext.setLoader(true);
    UpdatePayoutStatusApi(params) // Call your API to update status
      .then((res) => {
        globalContext.setLoader(false);
        console.log("response::", res);
        // Refresh the transactions after updating the status
        // getTransactions(selectedStatus);
        getTransactions(selectedStatus, search, page - 1);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.error("Error updating status:", err);
      });
  };


  return (
    <Container fluid>
      <div className="dashboard-area-heading management-heading payroll-box">
        <h2>Payout History</h2>
        <strong className="total-earning">Total Earnings :{totalearning ? "$ " + totalearning.toFixed(2) : "N/A"}</strong>
      </div>
      <div className="dashboad-table transaction-area pt-2">
        <div className="d-flex dashboard-top-filter-area mb-3">
          <div className="position-relative">
            <select value={selectedStatus} onChange={handleStatusChange} className="form-select select-box">
            <option value="">All</option>
              <option value="PENDING">PENDING</option>
              <option value="COMPLETE">COMPLETED</option>
            </select>
            <i class="fa fa-angle-down down-icon" aria-hidden="true"></i>
          </div>

          <div className="export-btn export-btn-nft">
            <div className="user-searchbar">
              <SearchBar search={search} searchChange={searchChange} />
            </div>
          </div>
        </div>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>SR No</th>
              <th>Seller Name</th>
              <th>Actual Amount</th>
              <th>Total Payable Amount</th>
              <th>Commission</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((d, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.sellerFirstName && d.sellerLastName ? `${d.sellerFirstName} ${d.sellerLastName}` : "N/A"}</td>
                  <td>{d.atual_amount ? "$ " + d.atual_amount.toFixed(2) : "N/A"}</td>
                  <td>{d.payable_amount ? "$ " + d.payable_amount.toFixed(2) : "N/A"}</td>
                  <td>{d.admin_comission ? "$ " + d.admin_comission.toFixed(2) : "N/A"}</td>
                  <td>
                    {d.status == "PENDING" ?
                      <>
                        <Dropdown onSelect={(eventKey) => handleStatusChangeList(d, eventKey)} className="status-dropdown">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {d.status =="COMPLETE" ? "Completed" : d.status == "PENDING" && "Pending" }
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="PENDING">PENDING</Dropdown.Item>
                            <Dropdown.Item eventKey="COMPLETE">COMPLETED</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                      :
                      <>{d.status =="COMPLETE" ? "Completed" : d.status == "PENDING" && "Pending" }</>
                    }
                  </td>
                  <td>{ShowFormatedDate(d.created_at)}</td>
                  <td>
                    <span
                      className="view-bank"
                      role="button"
                      onClick={() => handleShow(d.user_id)} // Assuming d.bankDetails contains the bank info
                      title="View Bank"
                    >
                      👁️ View Bank
                    </span>
                    <span
                      className="view-bank main"
                      role="button"
                      title="View Detail"
                      onClick={() => navigate(`/payout_details?payoutId=${d.id}`)}
                    >
                      👁️ View Details
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="not-data-found">
                  {msg}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {totalItems > 10 && (
          <DataPagination page={page} totalItems={totalItems} changePage={changePage} />
        )}
      </div>
      {/* Modal for Bank Details */}
      <BankDetailModal
        show={showModal}
        handleClose={handleClose}
        bankDetails={selectedBankDetails}
      />
    </Container>
  );
}
