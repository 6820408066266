import React, { useContext } from "react"
import { Button } from "react-bootstrap"
import { GlobalContext } from "../context/GlobalContext"
import { ExportToCsv } from "../services/Services";
import { ReactTooltip } from "./ReactTooltip";

export const ExportCsvData = ({ data, selectAll }) => {

    const globalContext = useContext(GlobalContext);

    function downloadCsv() {
        let ids = "ids="
        if (!globalContext.selectAllUsers && !globalContext.selectAllTrans && !globalContext.selectAllNft && !selectAll) ids = "ids=" + globalContext.selectedData.toString()
        if (selectAll && !globalContext.selectAllUsers && !globalContext.selectAllTrans && !globalContext.selectAllNft) ids = "nids=" + globalContext.unSelectedData.toString()
        if (globalContext.selectedData.length) {
            // Download csv file
            ExportToCsv(data, ids)
        }
    }

    return (
        <ReactTooltip text={globalContext.selectedData.length > 0 ? "Export selected item" : "Select item to export"}>
            <div>
                <Button type="button" variant="unset" onClick={downloadCsv} disabled={globalContext.selectedData.length === 0}>
                    <i className="fa fa-download" aria-hidden="true"></i> Export CSV
                </Button>
            </div>
        </ReactTooltip>
    )
}