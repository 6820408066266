import React from "react"
import { Form } from "react-bootstrap"

// search bar component
export const SearchBar = ({ search, searchChange }) => {
    return (
        <Form className="d-flex">
            <Form.Control
                type="search"
                placeholder="Search Here..."
                aria-label="Search"
                value={search}
                onChange={searchChange}
            />
            <i className="fa fa-search" aria-hidden="true"></i>
        </Form>
    )
}