import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { NftData } from "./NftData";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NftListApi, UpdateNftStatus } from "../../services/Services";
import { GlobalContext } from "../../context/GlobalContext";
import Swal from "sweetalert2";
import { DataPagination } from "../../common/DataPagination";
import { SubText } from "../../common/SubText";
import { CommonTransactions } from "../transactionManagement/CommonTransactions";
import { ImageShimmer } from "../../common/ImageShimmer";
import { ReactTooltip } from "../../common/ReactTooltip";
import { SelectAllDataCsv } from "../../common/SelectAllDataCsv";
import { SelectParticularItem } from "../../common/SelectParticularItem";
import { SearchBar } from "../../common/SearchBar";
import { ExportCsvData } from "../../common/ExportCsvData";

export const NftManagement = () => {

  const [query] = useSearchParams();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [nftDetail, setNftDetail] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [msg, setMsg] = useState("Loading...")

  useEffect(() => {
    setMsg("Loading...");
    let currentPage = query.get("page");
    setPage(parseInt(currentPage));
    getNftTransactions(parseInt(currentPage) - 1);
  }, [query, search]);

  const getNftTransactions = (currentPage) => {
    globalContext.setLoader(true)
    NftListApi(query.get("search") ? query.get("search") : search, currentPage)
      .then((res) => {
        globalContext.setLoader(false)
        setMsg("No Data Found");
        setData(res.data.data);
        setNftDetail(res.data.data.length > 0 ? res.data.data[0] : {});
        setTotalItems(res.data.total_records);
        // when selected all, change check box data
        if (globalContext.selectAllNft || selectAll) {
          let temp = [...globalContext.selectedData]
          // only push id when not already in the array
          res.data.data.forEach(d => {
            if (!globalContext.selectedData.some((s) => s === d.id) && !globalContext.unSelectedData.some((s) => s === d.id)) temp.push(d.id)
          })
          globalContext.setSelectedData(temp)
        }
      })
      .catch((err) => {
        globalContext.setLoader(false)
        if (err.response.status === 401) {
          globalContext.setSessionComplete(true);
        }
        console.log("nft management error ", err);
      });
  };

  const changeNftStatus = (nft, index) => {
    Swal.fire({
      title: `${nft.nft_enabled === "YES" ? "Deactivate" : "Activate"}`,
      text: `Are you sure, you want to ${nft.nft_enabled === "YES" ? "deactivate" : "activate"} Trading cards?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          status: nft.nft_enabled === "YES" ? "NO" : "YES",
        };
        UpdateNftStatus(params, nft.id)
          .then((res) => {
            let temp = [...data];
            temp[index].nft_enabled = nft.nft_enabled === "NO" ? "YES" : "NO";
            setData(temp);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              globalContext.setSessionComplete(true);
            }
            console.log("nft update status error ", err);
          });
      }
    });
  };

  const changePage = (newPage) => {
    navigate({ search: `?page=${newPage}` });
  };

  const searchChange = (e) => {
    navigate({ search: `?page=${1}` });
    setSearch(e.target.value.trimStart())
  };
  function handleContextMenu(event) {
    event.preventDefault();
}

  return (
    <>
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <h2>Trading Cards Management</h2>
        </div>

        <Row className="pb-4">
          <Col md={5}>
            <div className="dashboad-table user-management user-content-area">
              <div className="management-area-top nft-area-top">
                <div className="export-btn export-btn-nft">
                  {/* Export csv component */}
                  <ExportCsvData data="nfts" selectAll={selectAll} />
                </div>
                {/* search bar component */}
                <div className="user-searchbar">
                  <SearchBar
                    search={query.get("search") ? query.get("search") : search}
                    searchChange={searchChange}
                  />
                </div>
              </div>

              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>
                      <SelectAllDataCsv data={data} type="nft" setSelectAll={setSelectAll} />
                    </th>
                    <th>Trading Cards</th>
                    <th>Image</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length ? (
                    data.map((d, i) => (

                      <tr
                        className={`list-data ${nftDetail === d ? "active" : ""}`}
                        key={i}
                        onClick={() => setNftDetail(d)}
                      >
                        <td>
                          <SelectParticularItem item={d.id} />
                        </td>
                        <td>{d.nft_name}</td>
                        <td className="nft-pitcures">
                          <td className="nft-pitcures">
                            {d.nft_media_type === null || d.nft_media_type === "image" ? (
                              <ImageShimmer source={d.nft_image} width={55} height={55} />
                            ) : (
                              <video width={55} height={55} controls={false}>
                                <source src={d.nft_image} type="video/mp4" 
                                 onContextMenu={handleContextMenu}
                                 controlsList="nodownload"
                                 preload='none'
                                    playsinline
                                  />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </td>

                        </td>
                        <td>
                          {d.nft_description.length > 15
                            ? SubText(d.nft_description)
                            : d.nft_description}
                        </td>
                        {globalContext.userInfo.role === "ADMIN" || globalContext.userInfo.nft_mgt_write === 1 ?
                          <td>
                            {/* Tooltip component using react bootstrap */}
                            <ReactTooltip text={d.nft_enabled === "YES" ? "Disable" : "Enable"}>
                              <div className="user-toggle" id="my-element">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={
                                      d.nft_enabled === "YES" ? true : false
                                    }
                                    onChange={(e) => changeNftStatus(d, i)}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </ReactTooltip>
                          </td> : <td>{d.nft_enabled === "YES" ? "Activated" : "Deactivated"}</td>}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="not-data-found">
                        {msg}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* Pagination common component */}
              {totalItems > 10 && (
                <DataPagination
                  page={page}
                  totalItems={totalItems}
                  changePage={changePage}
                />
              )}
            </div>
          </Col>
          <Col md={7}>
            {/* NFT detail component */}
            <NftData {...nftDetail} />
            {/* particular NFT transactions component */}
            <CommonTransactions {...nftDetail} apiType="nftTransactions" />
          </Col>
        </Row>
      </Container>
    </>
  );
};
