import React, { useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataPagination } from "../../common/DataPagination";
import { DateRangeFilter } from "../../common/DateRangeFilter";
import { ExportCsvData } from "../../common/ExportCsvData";
import { ShowFormatedDate } from "../../common/FormatDate";
import { ReactTooltip } from "../../common/ReactTooltip";
import { SelectAllDataCsv } from "../../common/SelectAllDataCsv";
import { SelectParticularItem } from "../../common/SelectParticularItem";
import { SubText } from "../../common/SubText";
import { WeiToEth } from "../../common/WeiToEth";
import { GlobalContext } from "../../context/GlobalContext";
import { TransactionsApi } from "../../services/Services";
import { ADMIN_WALLET } from "../../configuration/Config";

export const TransactionManagement = () => {

  const [query] = useSearchParams();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [dateFilter, setDateFilter] = useState({ start: "", end: "" });
  const [selectAll, setSelectAll] = useState(false);
  const [msg, setMsg] = useState("Loading...")

  useEffect(() => {
    setMsg("Loading...");
    let currentPage = query.get("page");
    setPage(parseInt(currentPage));
    getTransactions(parseInt(currentPage) - 1);
  }, [query, dateFilter]);

  const getTransactions = (currentPage) => {
    globalContext.setLoader(true)
    TransactionsApi(currentPage, dateFilter.start, dateFilter.end)
      .then((res) => {
        globalContext.setLoader(false)
        setMsg("No Data Found");
        setData(res.data.data);
        setTotalItems(res.data.total_records);
        // when selected all, change check box data
        if (globalContext.selectAllTrans || selectAll) {
          let temp = [...globalContext.selectedData]
          // only push id when not already in the array
          res.data.data.forEach(d => {
            if (!globalContext.selectedData.some((s) => s === d.id) && !globalContext.unSelectedData.some((s) => s === d.id)) temp.push(d.id)
          })
          globalContext.setSelectedData(temp)
        }
      })
      .catch((err) => {
        globalContext.setLoader(false)
        if (err.response.status === 401) {
          globalContext.setSessionComplete(true);
        }
        console.log("transaction management error ", err);
      });
  };

  const changePage = (newPage) => {
    navigate({ search: `?page=${newPage}` });
  };

  return (
    <Container fluid>
      <div className="dashboard-area-heading management-heading">
        <h2>Transaction Management</h2>
      </div>
      <div className="dashboad-table transaction-area pt-2">
        <div className="d-flex dashboard-top-filter-area mb-3">
          {/* Date filter component */}
          <DateRangeFilter
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
          <div className="export-btn export-btn-nft">
            {/* Export csv component */}
            <ExportCsvData data="transactions" selectAll={selectAll} />
          </div>
        </div>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>
                <SelectAllDataCsv data={data} type="trans" setSelectAll={setSelectAll} />
              </th>
              {/* <th>Event</th> */}
              <th>Trading Cards</th>
              <th>Quantity</th>
              <th>Total Price</th>
              <th>Commission</th>
              <th>From User Name</th>
              <th>To User Name</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((d, i) => (
                <tr key={i}>
                  <td>
                    <SelectParticularItem item={d.id} />
                  </td>
                  {/* <td>
                    <img
                      src={require("../../assets/images/minted.png")}
                      alt="sale"
                    />{" "}
                    {"ITEM BUY"}
                  </td> */}
                  <td>
                    <div>{d.nft_name ?<span role="button" onClick={() => navigate(`/tradingcards_management?page=1&search=${d.nft_name}`)}>{d.nft_name}</span> : "N/A"}</div>
                  </td>
                  <td>{d.quantity ? d.quantity : "N/A"}</td>
                  <td>{d.total_Price ? "$ " + d.total_Price : "N/A"}</td>
                  <td>{d.admin_comission ? "$ "+ d.admin_comission : "N/A" } </td>
                  
                  <td>
                                        {d.sellerFirstName && d.sellerLastName ? `${d.sellerFirstName} ${d.sellerLastName}` : "N/A"}
                                </td>
                                <td>
                                   
                                    {d.userFirstName && d.userLastName ? `${d.userFirstName} ${d.userLastName}` : "N/A"}
                                </td>
                  <td>
                    {ShowFormatedDate(d.created_at)}{" "}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="not-data-found">
                  {msg}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* Pagination component */}
        {totalItems > 10 && (
          <DataPagination
            page={page}
            totalItems={totalItems}
            changePage={changePage}
          />
        )}
      </div>
    </Container>
  );
}
