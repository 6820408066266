import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GetQR } from "../../services/Services";
import { DisableTwoFa } from "./DisableFa";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { ThirdStep } from "./ThirdStep";

export const FaSetup = () => {

  const [steps, setSteps] = useState({ first: true, second: false, third: false })
  const [qr, setQr] = useState("");
  const [securityKey, setSecurityKey] = useState("");
  const [faStatus, setFaStatus] = useState("");
  const [updatePage, setUpdatePage] = useState(0);

  useEffect(() => {
    getQrCode()
  }, [updatePage])

  const getQrCode = () => {
    GetQR().then(res => {
      setFaStatus(res.data.data.user_2fa_status)
      setQr(res.data.data.qr_code)
      setSecurityKey(res.data.data.user_2fa_security_key)
    }).catch(err => {
      console.log("get qr error", err);
    })
  }

  return (
    <>
      <section className="botmanagement_section edit_bot_details profile_section">
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="sale_graph transaction_box mb-3">
                <div className="trans_head pb-1">
                  <div>
                    <h2 className="mb-1">2FA Setup</h2>
                  </div>
                </div>
                {faStatus === "DISABLED" &&
                  <>
                    {steps.first &&
                      <FirstStep steps={steps} setSteps={setSteps} qr={qr} securityKey={securityKey} />}

                    {steps.second &&
                      <SecondStep steps={steps} setSteps={setSteps} securityKey={securityKey} />}

                    {steps.third &&
                      <ThirdStep steps={steps} setSteps={setSteps} setUpdatePage={setUpdatePage} />}
                  </>
                }
                {faStatus === "ENABLED" &&
                  <DisableTwoFa setUpdatePage={setUpdatePage} />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
