import React from "react";
import { PageNotFound } from "../common/PageNotFound";
import { FaSetup } from "../components/2fa/FaSetup";
import { Profile } from "../components/profile/Profile";

export const RoutePath = [
    {
        path: "/fa_setup",
        element: <FaSetup />
    },
    {
        path: "/profile",
        element: <Profile />
    },
    // This is for 404 page if user try to navigate to any other unknown path
    {
        path: "*",
        element: <PageNotFound />
    }
]