import React, { useState } from "react"
import { Form } from "react-bootstrap"
import Select from "react-select";
import { FormatDate } from "./FormatDate";

export const DateRangeFilter = ({ dateFilter, setDateFilter }) => {

    const [selectedOption, setSelectedOption] = useState({ value: "", label: "Select Duration" });
    const todayDate = FormatDate(new Date());

    // Options for the date filter
    const options = [
        { value: "week", label: "Last week" },
        { value: "month", label: "1 month" },
        { value: "custom", label: "Custom duration" },
    ];

    const changeFilter = (date) => {
        let today = new Date()
        if (date.value !== "custom") {
            let subDate = 0
            if (date.value === "week") {
                subDate = 7
            } else {
                subDate = 30
            }
            // Used to send startdate and enddate according to week and month
            setDateFilter({ start: FormatDate(today.setDate(today.getDate() - subDate)), end: todayDate })
        }
        setSelectedOption(date)
    }

    const changeStartDate = (date) => {
        if (dateFilter.end && date <= dateFilter.end && date <= todayDate) {
            setDateFilter({ ...dateFilter, start: date })
        } else if (!dateFilter.end && date <= todayDate) {
            setDateFilter({ ...dateFilter, start: date })
        }
    }

    const changeEndDate = (date) => {
        if (date <= todayDate && date >= dateFilter.start) setDateFilter({ ...dateFilter, end: date })
    }

    return (
        <>
            {selectedOption.value === "custom" && <>
                <div className="system-log-area-date-picker">
                    <div>
                        <label>Start Date</label>
                    </div>
                    <div>
                        <input type="date" max={dateFilter.end ? dateFilter.end : todayDate} value={dateFilter.start} onChange={(e) => changeStartDate(e.target.value)} />
                    </div>
                </div>
                <div className="system-log-area-date-picker">
                    <div>
                        <label>Last Date</label>
                    </div>
                    <div>
                        <input type="date" min={dateFilter.start} max={todayDate} disabled={dateFilter.start ? false : true} value={dateFilter.end} onChange={(e) => changeEndDate(e.target.value)} />
                    </div>
                </div>
            </>}
            <div className="user-management dashboard-filter-btn">
                <Form.Group
                    className="mt-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Select defaultValue={selectedOption} options={options} onChange={(e) => changeFilter(e)} />
                </Form.Group>
            </div>
        </>
    )
}