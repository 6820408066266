import React from "react";
import { Button } from "react-bootstrap";
import { ImageShimmer } from "../../common/ImageShimmer";

export const FirstStep = ({ steps, setSteps, qr, securityKey }) => {
    return (
        <div className="step-to-step">
            <h2>Step 1</h2>
            <h6>Scan this qr code in the Google Authenticator app</h6>
            <div className="qr-code">
                <ImageShimmer source={qr} width={200} height={200} />
                <div>
                    <p>
                        if you are unable to scan the qr code, please enter
                        this security key manually in to the app
                    </p>
                    <h3>{securityKey}</h3>
                </div>
            </div>
            <Button
                type="button"
                className="next-bt"
                onClick={() => setSteps({ ...steps, first: false, second: true })}
            >
                Next
            </Button>
        </div>
    )
}