import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";

export const SidebarOption = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const globalContext = useContext(GlobalContext)
    const [currentTab, setCurrentTab] = useState("home");

    useEffect(() => {
        // Get the pathname from location and set active tab for sidebar
        let activeTab = location.pathname.split("/").pop();
        setCurrentTab(activeTab);
    });


    const switchPage = (loc) => {
        if (loc === "sign_out") {
            // Handled the logout functionality
            sessionStorage.clear();
            globalContext.setLoggedIn(false);
            navigate('/');
        } else {
            setCurrentTab(loc);
            navigate(`/${loc}`);
        }
    };

    return (
        <div onClick={() => switchPage(props.path)}>
            <span className={`sidebar_option ${currentTab.includes(props.tabName) ? "active" : ""}`}>
                <img alt="" src={props.img} />
                {props.name}
            </span>
        </div>
    )
}